import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Privacy from "../molecules/privacy/Privacy";

const privacyPage = () => {
  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO
        description={`"Learn about Goken's privacy policy, how we protect your personal information, and our commitment to data security and transparency.`}
        canonicalLink={"https://www.goken-global.com/privacy/"}
        title="Goken | Privacy Policy for Goken Website"
      ></SEO>
      <Privacy></Privacy>
    </Layout>
  );
};

export default privacyPage;
