import React from "react";

const Privacy = () => {
  return (
    <div className="privacy-wrapper m-top-20 text-center">
      <h1
        className="heading privacy-title"
        style={{
          textAlign: "center",
          padding: "20px",
          margin: "0px",
          marginTop: "50px",
        }}
      >
        PRIVACY POLICY FOR GOKEN AMERICA, LLC’S WEBSITE
      </h1>
      <h2 className="semi-title privacy-para" style={{ textAlign: "center" }}>
        PLEASE READ THIS PRIVACY POLICY CAREFULLY BEFORE USING THIS WEBSITE.
      </h2>

      <h4 className="cards-text">OUR THOUGHTS ON PRIVACY </h4>
      <div className="para">
        The operator of this website, namely, GOKEN AMERICA, LLC (“GOKEN” or
        “we”, “us” or “our”), has created this privacy statement in order to
        demonstrate its commitment to privacy. We urge you to read the privacy
        policy in its entirety so that you will understand all of the practices
        and procedures we follow relating to your online privacy.
      </div>
      <h4 className="cards-text">WHAT THIS PRIVACY POLICY COVERS </h4>
      <div className="para">
        This privacy policy will inform you about the type of information that
        is collected about you when you use this website and any other website
        that GOKEN might offer (collectively referred to as the “Website”), how
        the information is collected, what the information will be used for, and
        to whom it will be given. This privacy policy will also tell you how to
        limit our use of your personal information.
      </div>
      <h4 className="cards-text">CONTACT THE WEBSITE </h4>
      <div className="para">
        The Website is the responsibility of GOKEN. If you have any questions
        about this privacy statement, the Website, or your use of the Website,
        you can contact us by e-mail at contactus@gokenamerica.com or by U.S.
        Mail at GOKEN AMERICA, LLC, 5100 Parkcenter Avenue, Dublin, Ohio 43017.
        You can reach us by phone at (614) 495-8100.
      </div>
      <h4 className="cards-text">
        {" "}
        WHAT KIND OF PERSONAL INFORMATION GOKEN COLLECTS{" "}
      </h4>
      <div className="para">
        <p>
          The privacy and security of your information is important to us. We
          give you the option of signing up to receive email updates. If you
          choose to register with us, we require you to give us your name and
          e-mail address. In addition, the Website collects contact information
          in connection with other users whom you will manage with the Website.
          We take care to protect the on-line data you submit to us when using
          the Website and store such information in a secure database.{" "}
        </p>
        <p>
          Additionally, some information about your server address, web browser,
          and computer system are automatically transmitted to our web servers
          so that we can operate and improve the Website. For example, we track
          the number of people who view each page, the order in which pages are
          viewed, and other information to learn which features are the most
          popular and to refine our design and marketing efforts. We collect and
          store such information on an aggregated (collected) basis.
        </p>
        <p>
          As part of the services offered at the Website, we reserve the right
          to collect automatically and track:
        </p>
        <ul>
          <li>
            The home server, IP addresses and domain names, e-mail addresses,
            type of computer, and type of web browser you are using while you
            visit the Website; and
          </li>
          <li>
            Other information volunteered by you when you use the Website.{" "}
          </li>
        </ul>
      </div>
      <h4 className="cards-text">HOW WE USE YOUR PERSONAL INFORMATION </h4>
      <div className="para">
        <ul>
          <li>
            We use your email address to send you updates about or in connection
            with our services, new services, and e-mailed support requests or
            about a material change to this Privacy Policy or the Terms and
            Conditions, or to comply with applicable statutes and regulations or
            legal process served on GOKEN.{" "}
          </li>
          <li>
            Aggregated Uses of Information:
            <ul>
              <li>
                We use IP addresses to help diagnose problems with our server
                and to administer the Website, to authenticate user accounts,
                and to gather broad demographic information.
              </li>
              <li>
                We use demographic and profile data only for personalized
                content and statistical information and do not share those data
                with other companies except in ways that do not personally
                identify our users. We use these data to tailor the visitor's
                experience at the Website, showing you content that we think
                might interest you, and displaying the content according to your
                preferences.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          In all cases, users may opt out of receiving future mailings. Please
          see the “Opting-Out” section below for more information.
        </p>
        <p>
          GOKEN will not share your e-mail address with anyone else, except that
          GOKEN may disclose information about its users if required to do so by
          law or in the good-faith belief that such disclosure is reasonably
          necessary to respond to subpoenas, court orders, or other legal
          process. We may also disclose information about users to
          law-enforcement officers or others in the good-faith belief that such
          disclosure is reasonably necessary to enforce its terms of use,
          respond to claims that any posting or other content violates the
          rights of third parties, or to protect the rights, property, or safety
          of GOKEN, its users or the general public.{" "}
        </p>
      </div>
      <h4 className="cards-text">PUBLIC MESSAGE BOARDS AND FORUMS </h4>
      <div className="para">
        The Website may make chat rooms, forums, message boards, or news groups
        available to its users. Please remember that any information that is
        disclosed in these areas becomes public information and you should
        exercise caution when deciding to disclose your personal information.
      </div>
      <h4 className="cards-text">SECURITY </h4>
      <div className="para">
        The security of all personally identifying information associated with
        our users is of great concern to us. Although perfect security does not
        exist in any transaction process on line or in stores the Website have
        security measures in place to protect the loss, misuse and alteration of
        the information under our control. Please be assured that we are
        constantly reviewing our security processes to keep up to date with the
        absolute highest levels of security encryption and service.
      </div>
      <h4 className="cards-text">COOKIES </h4>
      <div className="para">
        <p>
          A "cookie" is a small file sent from a web server to your browser and
          is stored on your computer’s hard drive when you visit certain
          Website. Cookies help web surfing by saving visitor preferences and
          other information. The use of cookies is a widespread industry
          standard and thus many major Website use cookies.{" "}
        </p>
        <p>
          Most Web browsers are initially configured to accept and process
          cookies. You can configure your browser to refuse cookies.
        </p>
        <p>
          GOKEN uses cookies to keep track of your logged-in status. We reserve
          the right to use cookies to customize content based on the pages you
          have looked at. If you have any questions concerning our use of
          cookies, please contact us at contactus@gokenamerica.com.
        </p>
      </div>
      <h4 className="cards-text">OPTING-OUT </h4>
      <div className="para">
        <p>
          The Website provides user the opportunity to opt out of receiving
          communications from us at the point where we request information about
          the visitor. At your request, we shall remove all of your information
          from our database.
        </p>
        <p>
          You can send your request by e-mail to contactus@gokenamerica.com, or
          by phone at (614) 495-8100, or by U.S. Mail at GOKEN AMERICA, LLC,
          5100 Parkcenter Avenue, Dublin, Ohio 43017, in order to unsubscribe
          from any or all email lists.{" "}
        </p>
      </div>
      <h4 className="cards-text">
        REQUESTING TO SEE YOUR PERSONAL INFORMATION{" "}
      </h4>
      <div className="para">
        You may request via e-mail, U.S. Mail or telephone that we provide you
        with the personal information we have concerning you. Once you receive
        this information, you may request that we modify or remove any of your
        personal information. We reserve the right to verify your identity
        before sending you this information.
      </div>
      <h4 className="cards-text">LINKS TO OTHER SITES </h4>
      <div className="para">
        The Website may contain links to other sites. GOKEN is not responsible
        for the privacy practices or the content of such other sites.
        Occasionally, you may receive cookies from third-party advertisers,
        partners and vendors. We do not control these cookies.
      </div>
      <h4 className="cards-text">CALIFORNIA RESIDENTS</h4>
      <div className="para">
        <p>
          Residents of California, thanks to section 1798.83 of the California
          Civil Code, may request, once per calendar year, from companies
          conducting business in California a list of all third parties to whom
          the company has disclosed certain personally identifiable information
          as defined under California law during the preceding year for
          third-party direct-marketing purposes. In your request, please attest
          to your being a California resident and provide a current California
          address for our response.{" "}
        </p>
        <p>
          If you are a California resident and under the age of 18, and a
          registered user of this Website, section 22581 of the California
          Business and Professions Code permits you to request and obtain the
          removal of content that you have publicly posted. Please note that
          your request will not ensure a complete removal of the content and
          that in some cases the statute may not require or allow removal even
          if requested.
        </p>
      </div>
      <h4 className="cards-text">
        NOTICE ABOUT THE EUROPEAN UNION’S GENERAL DATA PROTECTION REGULATIONS
        (“GDPR”)
      </h4>
      <div className="para">
        <p>
          You may have certain rights under the European Union’s GDPR 2016/679.
          The GDPR is a complex regulatory scheme and we do not intend to
          provide every detail here. If interested you should read the GDPR and
          the relevant guidance provided by the applicable authorities for a
          fuller explanation.
        </p>
        <p>
          The GDPR provides the right, where applicable, to (a) access, (b)
          publication, (c) erasure, (d) restrict processing, (e) object to
          processing, (f) data portability, (g) complain to a supervisory
          authority, and (h) withdraw consent.
        </p>
        <p>
          Where applicable, the GDPR provides the right to confirm whether GOKEN
          has processed your Personal Data (as defined in the GDPR) and, when
          GOKEN, to access those data, together with certain other information
          including the purposes of the processing, the categories of Personal
          Data concerned, and the recipients of the Personal Data. Where
          applicable, and providing that we do not affect the rights and
          freedoms of anyone else, we will supply you with one copy of your
          Personal Data. The first copy is free. We may charge a reasonable fee
          for additional copies.
        </p>
        <p>
          Where applicable, the GDPR provides the rights to rectify any
          inaccurate Personal Data about you and, taking into account the
          purposes of the processing, to complete any incomplete data about you.
        </p>
        <p>
          In some circumstances the GDPR provides you the right to erase your
          Personal Data without undue delay, including when we no longer need
          the Personal Data for the purposes for which we collected or processed
          the Personal Data, if you withdraw your consent to consent-based
          processing, if you object to the processing of the Personal Data under
          certain rules of the GDPR, if the processing is for the purpose of
          direct marketing, and if the Personal Data have been unlawfully
          processed. However, the GDPR provides exceptions or exclusions to the
          right to erase, including where the processing is necessary for the
          exercise of free expression and information, to comply with a legal
          obligation, or to establish, exercise, or defend a legal claim.
        </p>
        <p>
          In some circumstances the GDPR provides the right to restrict the
          processing of your Personal Data, such as to contest the accuracy of
          the Personal Data, where the processing is unlawful but you oppose
          erasure, when we no longer need the Personal Data for the purpose of
          our processing but you require Personal Data to establish, exercise or
          defend a legal claim, and you have objected to the processing pending
          the verification of that objection. When processing has been
          restricted on this basis, we may continue to store your Personal Data.
          But we will process your data otherwise only with your consent, to
          establish, exercise or defend a legal claim, or to protect another
          natural or legal person’s rights, or for reasons of important public
          interest.{" "}
        </p>
        <p>
          If applicable, the GDPR provides the right to object to our processing
          of your Personal Data on grounds relating to your situation, but only
          to the extent that the processing is necessary for a task carried out
          in the public interest or to exercise any official authority vested in
          GOKEN, or for a legitimate interest pursued by GOKEN or someone else.
          If you object, we shall stop processing the Personal Data unless we
          can demonstrate compelling legitimate grounds to process the
          information that override your interests, rights and freedoms, or the
          purpose of the processing is to establish, exercise or defend a legal
          claim.{" "}
        </p>
        <p>
          You may object to our processing of your Personal Data for direct
          marketing purposes including profiling for direct marketing purposes.
          If you notify us of such an objection, we shall stop processing your
          Personal Data for that purpose.
        </p>
        <p>
          You may object to our processing of your Personal Data for scientific
          or historical research or statistical purposes on grounds relating to
          your particular situation, unless the processing is necessary for a
          task carried out in the public interest.
        </p>
        <p>
          If the legal basis for our processing your Personal Data is consent or
          that the processing is necessary to perform a contract to which you
          are a party, or in order to take steps at your request before entering
          into a contract, and such processing is carried out by automatic
          means, you have the right to receive your Personal Data from us in a
          structured, commonly used, and machine-readable format. But this right
          does not apply when its application ill adversely affect the rights
          and freedoms of others.
        </p>
        <p>
          If you believe that our processing of your Personal Data infringes any
          data-protection statute, rule or regulation, you may lodge a complaint
          with the appropriate authorities responsible for data protection. If
          applicable, you may lodge that complaint in the European Union member
          state of your habitual residence, your workplace, or the place of the
          alleged infringement.
        </p>
        <p>
          To the extent that we base our processing of your Personal Data on
          your consent, you may withdraw that consent at any time. But your
          withdrawal of that consent will not affect the lawfulness of any
          processing we do before your withdrawal.
        </p>
        <p>
          If applicable, you may exercise any of your rights in relation to your
          Personal Data by emailing GOKEN at contactus@gokenamerica.com.{" "}
        </p>
      </div>

      <h4 className="cards-text">ACCESS TO CHILDREN</h4>
      <div className="para">
        <p>
          {" "}
          We do not direct any features of the Website to children under the age
          of 13. Children under 13 may not register with GOKEN, download,
          install, or use any of the services available on the Website.{" "}
        </p>
        <p>
          Although GOKEN does not currently direct Website that it operates to
          children under the age of 13, any forthcoming version of this Website
          that interact with children under the age of 13 will always require
          parental consent prior to collecting personal information. Any such
          Website will also provide parents with access to information collected
          about their child and the opportunity to opt out of future collection
          and have us delete such information. Without prior parental consent,
          GOKEN will use a child’s on-line information only to respond directly
          to the child's request and will not use it for other purposes without
          prior parental consent.{" "}
        </p>
        <p>
          GOKEN strongly encourages parents and guardians to supervise their
          children's online activities and to consider using parental control
          tools available from online services and software manufacturers to
          help provide a child-friendly online environment. These tools also can
          prevent children from disclosing online their name, address, and other
          personally identifiable information without parental permission.
          Products and services for sale are intended for purchase by adults. By
          making a purchase on the Website, you agree that you are at least 13
          years of age.{" "}
        </p>
        <p>
          GOKEN and this Website will...
          <ul>
            <li>
              NOT collect personally identifiable off-line contact information
              from children under the age of 13 without prior parental consent.
            </li>
            <li>
              NOT distribute to third parties any personally identifiable
              information with respect to children under the age of 13 without
              prior parental consent.
            </li>
            <li>
              NOT give the ability to post publicly or otherwise distribute
              personally identifiable contact information with respect to
              children under the age of 13 without prior parental consent.
            </li>
            <li>
              NOT entice children under the age of 13 by the prospect of a
              special game, prize or other activity, to divulge more information
              than is needed to participate in the activity.
            </li>
          </ul>{" "}
        </p>
      </div>

      <h4 className="cards-text">CHANGES TO THIS PRIVACY POLICY</h4>
      <div className="para">
        We reserve the right to change this policy at any time. We ask that you
        review this privacy policy from time to time to ensure you are familiar
        with the most current version of it.
      </div>
      <h4 className="cards-text">ACCEPTANCE OF THIS PRIVACY POLICY</h4>
      <div className="para">
        By visiting or otherwise using the Website, you agree to the terms as
        well as any posted changes of this Privacy Policy. By visiting or
        otherwise using the Website, you hereby consent to the collection and
        use of this information by GOKEN. If you do not so agree, DO NOT use the
        Website.
        <p>Date of last update: June 01, 2021</p>
      </div>
    </div>
  );
};

export default Privacy;
